import { ColumnDirective, ColumnsDirective } from "@syncfusion/ej2-react-grids";
import { GridComponent, Inject } from "@syncfusion/ej2-react-grids";
import { Page, Search, Selection, Sort } from "@syncfusion/ej2-react-grids";
import { Toolbar } from "@syncfusion/ej2-react-grids";
import { faPlaceOfWorship } from "@fortawesome/free-solid-svg-icons";

//Importa componentes propios
import { BotonesCrud } from "../components/BotonesCrud.js";
import { SpinnerMedium } from "../components/SpinnerMedium.js";
import "../css/PageIglesias.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIglesias } from "../hooks/useIglesias";
import { onLoadGrid } from "../helpers/onLoadGrid.js";

function PageIglesias() {
  // llama al hook que retorna los datos de las iglesias
  const { iglesias, loading, error } = useIglesias();

  if (error) {
    return (
      <div>
        <h5>Se produjó un error al cargar los datos</h5>
        <p>error</p>
      </div>
    );
  }

  if (loading) {
    return (
      <div>
        <SpinnerMedium mensaje="Cargando iglesias ..." />
      </div>
    );
  } else {
    //opciones del toolbar del grid
    const toolbarOptions = ["Search"];

    //Opciones de selección
    const selectOptions = { type: "Single" };
    const pageOptions = {
      pageSize: 10,
      pageSizes: true,
    };

    let grid;
    const rowSelected = () => {
      if (grid) {
        /** Get the selected row indexes */
        // const selectedrowindex = grid.getSelectedRowIndexes();
        /** Get the selected records. */
        // const selectedrecords = grid.getSelectedRecords();

        habilitaBotones();
        // alert(selectedrowindex + " : " + JSON.stringify(selectedrecords));
      }
    };

    function habilitaBotones() {
      let bEdit = document.getElementById("btnEdit");
      if (bEdit != null) {
        bEdit.disabled = false;
      }

      let bDelete = document.getElementById("btnDelete");
      if (bDelete != null) {
        bDelete.disabled = false;
      }
    }

    function nombreTemplate(props) {
      return (
        <div className="empimg">
          <FontAwesomeIcon icon={faPlaceOfWorship} className="color-Icon" />
          <span id="NombreText">{props.Nombre}</span>
        </div>
      );
    }

    return (
      <div className="gridAnchoL">
        <BotonesCrud cabecera="Catálogo de Iglesias" />

        <GridComponent
          dataSource={iglesias}
          id="PageGrid"
          allowPaging={true}
          load={onLoadGrid()}
          toolbar={toolbarOptions}
          pageSettings={pageOptions}
          selectionSettings={selectOptions}
          rowSelected={rowSelected}
          ref={(g) => (grid = g)}
          allowSorting={true}
          enableStickyHeader={true}
          height="100%"
          width="100%"
        >
          <Inject services={[Page, Search, Toolbar, Selection, Sort]} />
          <ColumnsDirective>
            <ColumnDirective
              field="Id"
              width="100"
              textAlign="Center"
              isPrimaryKey={true}
              visible={false}
            />
            <ColumnDirective
              field="SitioWeb"
              width="100"
              textAlign="Center"
              visible={false}
            />
            <ColumnDirective
              field="UrlLogo"
              width="100"
              textAlign="Center"
              visible={false}
            />
            <ColumnDirective
              field="Nombre"
              headerText="Nombre Iglesia"
              headerTextAlign="Center"
              width="100"
              textAlign="Left"
              template={nombreTemplate}
            />
            <ColumnDirective
              field="Direccion"
              headerText="Dirección"
              headerTextAlign="Center"
              width="100"
              textAlign="Left"
            />
            <ColumnDirective
              field="FechaApertura"
              headerText="F. Apertura"
              headerTextAlign="Center"
              width="100"
              textAlign="Left"
            />
          </ColumnsDirective>
        </GridComponent>
      </div>
    );
  }
}
export default PageIglesias;
