export const onLoadGrid = (nombreGrid) => {
  let gridElement = document.getElementById(nombreGrid);
  if (gridElement && gridElement.ej2_instances[0]) {
    let gridInstance = gridElement.ej2_instances[0];
    /** height of the each row */
    const rowHeight = gridInstance.getRowHeight();
    /** Grid height */
    const gridHeight = gridInstance.height;
    /** initial page size */
    const pageSize = gridInstance.pageSettings.pageSize;
    /** new page size is obtained here */
    const pageResize = (gridHeight - pageSize * rowHeight) / rowHeight;
    gridInstance.pageSettings.pageSize = pageSize + Math.round(pageResize);
  }
};
