import React from "react";
import ReactDOM from "react-dom/client";
import { registerLicense } from "@syncfusion/ej2-base";
import { App } from "./App";
import { BrowserRouter } from "react-router-dom";

// instancia de SyncFusion
registerLicense(
  "Ngo9BigBOggjHTQxAR8/V1NHaF5cWWNCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWH9fc3VTRGVeUUR0WkU="
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>

  //<AppRouter />
  <BrowserRouter>
    <App />
  </BrowserRouter>

  // </React.StrictMode>
);
