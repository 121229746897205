import React from "react";

import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { faCirclePlus, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";

export const BotonesCrud = (props) => {
  return (
    <div className="container" id="barraOpciones">
      <div className="row">
        <div className="col-8">
          <h2 className="Display-4">{props.cabecera}</h2>
        </div>
        <div className="col-4 derecha">
          <div className="e-btn-group justify-content-end">
            <ButtonComponent id="btnAdd" disabled={false}>
              <FontAwesomeIcon icon={faCirclePlus} className="btnIcon-Add" />
            </ButtonComponent>
            <ButtonComponent id="btnEdit" disabled={true}>
              <FontAwesomeIcon icon={faEdit} className="btnIcon-Edit" />
            </ButtonComponent>
            <ButtonComponent id="btnDelete" disabled={true}>
              <FontAwesomeIcon icon={faTrashCan} className="btnIcon-Delete" />
            </ButtonComponent>
          </div>
        </div>
      </div>
    </div>
  );
};
