import { useEffect, useState } from "react";
import { axiosIglesias } from "../api/axiosApi";

export const useIglesias = () => {
  const [iglesias, setIglesias] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getIglesias = async () => {
    axiosIglesias
      .get()
      .then((response) => {
        setIglesias(response.data);
        return response.data;
      })
      .finally(() => setLoading(false))
      .catch((err) => {
        console.log("Axios Error", err);
        setError(err);
      });
  };

  useEffect(() => {
    setLoading(true);
    // setTimeout(getIglesias, 3000);

    getIglesias();
  }, []);
  // console.log(iglesias);
  return { iglesias, loading, error };
};
