import React from "react";

export const SpinnerMedium = (props) => {
  return (
    <div className="d-flex text-secundary align-items-center">
      <strong className="justify-content-center" role="status">
        {props.mensaje}
      </strong>
      <div
        className="spinner-border text-warning m-5 spinnerLoad"
        aria-hidden="true"
      ></div>
    </div>
  );
};
