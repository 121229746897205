import React from "react";

export const PageNotFound = () => {
  return (
    <>
      <h4>Página no existe</h4>
      <p>La página no existe</p>
    </>
  );
};

export default PageNotFound;
