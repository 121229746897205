import React from "react";

export const PageInicio = () => {
  return (
    <>
      <h4>Página de Inicio</h4>
      <p>Aquí se ubicará un dashboard</p>
    </>
  );
};

export default PageInicio;
