import React from "react";
import { Routes, Route } from "react-router-dom";
import PageIglesias from "./pages/PageIglesias.js";
import PageNotFound from "./pages/PageNotFound.js";
import { Header } from "./components/Header.js";
import PageInicio from "./pages/PageInicio.js";

function AppRouter() {
  return (
    <>
      <Header />
      <section className="contenidoPrincipal">
        <Routes>
          <Route path="/" element={<PageInicio />} />
          <Route path="/iglesias" element={<PageIglesias />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </section>
    </>
  );
}

export default AppRouter;
