import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

export const Header = () => {
  return (
    <div className="container bk-green">
      <Navbar expand="lg">
        <Navbar expand="lg">
          <Container className="justify-content-start ">
            <Navbar.Brand href="/">
              <img
                src="logocares.png"
                width="50"
                height="50"
                className="d-inline-block align-top"
                alt=""
              />
            </Navbar.Brand>
            <Navbar.Text className="px-3 contenedorLogo" href="/">
              Iglesia de Cristo Casa de Restauración
            </Navbar.Text>
          </Container>
        </Navbar>

        <Container>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-flex-start"
          >
            <Nav className="justify-content-center">
              <NavDropdown title="Catalogos" id="basic-nav-dropdown">
                <NavDropdown.Item href="/iglesias" className="{()}">
                  Iglesias
                </NavDropdown.Item>
                <NavDropdown.Item href="/ministros">Ministros</NavDropdown.Item>
                <NavDropdown.Item href="/templos">Templos</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/discipuladores">
                  Discipuladores
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/modulos">Módulos</NavDropdown.Item>
                <NavDropdown.Item href="/lecciones">Lecciones</NavDropdown.Item>
                <NavDropdown.Item href="/ciclos">Ciclos</NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title="Operaciones" id="basic-nav-dropdown">
                <NavDropdown.Item href="/inscripciones">
                  Inscripciones
                </NavDropdown.Item>
                <NavDropdown.Item href="/asistencia">
                  Registro asitencia
                </NavDropdown.Item>
                <NavDropdown.Item href="/evaluacion">
                  Registro evaluacion
                </NavDropdown.Item>
                <NavDropdown.Item href="/informe1">
                  Control de discipulado
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title="Semillero" id="basic-nav-dropdown">
                <NavDropdown.Item href="/inscripcion-semillero">
                  Inscripción
                </NavDropdown.Item>
                <NavDropdown.Item href="/asignacion-semillero">
                  Asignaciones
                </NavDropdown.Item>
                <NavDropdown.Item href="/resultado-semillero">
                  Registro resultado
                </NavDropdown.Item>
                <NavDropdown.Item href="/control-semillero">
                  Control de resultados
                </NavDropdown.Item>
                <NavDropdown.Item href="/traslado-semillero">
                  Traslado discipulador
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
        <Container>
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text>
              Usuario: <a href="/login">gsiquiej</a>
            </Navbar.Text>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};
